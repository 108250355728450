<template>
  <div>
    <Modal class="z-modal" @close-modal="$emit('close-modal')">
      <div class="p-24">
        <div v-if="currentForm === 'create-account'">
          <SeCreateAccount
            :navigate-to-url="myAccountPageUrl"
            :privacy-policy="privacyPolicy"
          />
          <div class="mt-24 base-medium-text text-grey600 text-center">
            {{ $lang('loginResources', 'signInSwitchText') }}
            <span
              class="ml-8 underline text-grey800 cursor-pointer select-none"
              @click="switchForm('login')"
            >
              {{ $lang('loginResources', 'formHeadingSignIn') }}
            </span>
          </div>
        </div>
        <div v-else-if="currentForm === 'reset-password'">
          <ForgotPasswordForm @switch-form="switchForm('login')" />
        </div>
        <div v-else>
          <SignInForm
            :navigate-to-url="myAccountPageUrl"
            @switch-form="switchForm('reset-password')"
          />
          <div class="pt-40 border-t border-grey600 flex flex-col">
            <div class="base-medium-text text-grey800 mb-12">
              {{ $lang('loginResources', 'createAccountUspHeading') }}
            </div>
            <div class="mb-12">
              <div
                v-for="usp in createAccountUsps"
                :key="`usermodal-createAccountUsp${usp}`"
                class="flex items-center small-base-regular-text mb-12"
              >
                <fa :icon="['fal', 'check']" class="mr-12"></fa>
                {{ usp }}
              </div>
            </div>
            <Button
              :text="$lang('loginResources', 'formHeadingCreateAccount')"
              :size="Sizes.lg"
              :theme="Themes.ghost"
              class="btn--wide"
              @click="switchForm('create-account')"
            />
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script setup lang="ts">
import { Sizes, Themes } from '../constants/button-layouts';
import Button from '../globals/Button.vue';
import Modal from '~/components/modals/Modal.vue';
import SeCreateAccount from '../SeCreateAccount.vue';
import SignInForm from '../SignInForm.vue';
import ForgotPasswordForm from '../ForgotPasswordForm.vue';
import { useGlobalContentStore } from '~/store/globalContent';
const config = useGlobalContentStore().config;
const route = useRoute();
const { $lang } = useNuxtApp();

const currentForm = ref('login');

const createAccountUsps = computed(() => {
  return config.currentMarket?.createAccountUsps || [];
});

const privacyPolicy = computed(() => {
  return config.currentMarket?.createAccountPrivacyPolicy || '';
});

const myAccountPageUrl = computed(() => {
  if (route.query.invite) {
    return config.currentMarket?.myAccountPageUrl + '?invite=true';
  }
  return config.currentMarket?.myAccountPageUrl || '/';
});

const switchForm = (type: string) => {
  currentForm.value = type;
};
</script>
